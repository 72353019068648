import React from 'react';
import PropTypes, { shape } from 'prop-types';
import cx from 'classnames';

import styles from './legal.module.sass';
import Layout from '../../components/layout';
import SEO from '../../components/common/seo';
import Banner from '../../components/blocks/banner';
import Article from '../../components/common/article';
import Footer from '../../components/common/footer';

const Legal = ({
  pageContext: {
    html,
    frontmatter: { title, urlPath, id },
  },
}) => {
  return (
    <Layout footer={false}>
      <SEO title={title} urlPath={urlPath} />
      <Banner>
        <h1 className={cx('h2', styles.banner)}>Bank Relationships</h1>
      </Banner>
      <section>
        <div className="container">
          <Article className={styles.article} html={html} />
        </div>
      </section>
      <Footer>
        {id === 'bank-relationships' && (
          <>
            <li>Kabbage, Inc. is not affiliated with American Express.</li>
            <li>
              Kabbage Funding is a trademark of American Express used under
              license.
            </li>
          </>
        )}
      </Footer>
    </Layout>
  );
};

Legal.propTypes = {
  pageContext: shape({
    html: PropTypes.string,
    frontmatter: shape({ title: PropTypes.string }),
  }).isRequired,
};

export default Legal;
